<template>
  <v-sheet
    rounded="xl"
    class="text-start pa-6 py-8"
    maxWidth="786px"
    :width="$vuetify.breakpoint.smAndUp && $vuetify.breakpoint.mdAndDown ? '586px' : ''"
  >
    <stepper :completeStep="sendingCompleted ? 4 : 3" :currentStep="3" />
    <v-row class="px-3">
      <v-col cols="12" class="d-flex justify-space-between">
        <div>
          Your transactions are being processed. You need to confirm transactions in your wallet to complete. <br />
          Please wait until all the transaction success
        </div>
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="data.length === 0"
          :size="20"
          class="align-self-center"
        ></v-progress-circular>
      </v-col>
      <v-col cols="12" class="d-flex justify-space-between grey--text text-caption pb-0">
        <div class="text-body-1">Txn hash</div>
        <div class="text-body-1">Status</div>
      </v-col>
      <v-col cols="12" class="trans-info-scrollable">
        <v-card
          style="cursor: pointer"
          v-for="(item, index) in data"
          :key="index"
          class="mb-1"
          elevation="0"
          @mouseenter="onMouseEnter(index)"
          @mouseleave="onMouseLeave(index)"
        >
          <div class="d-flex justify-space-between pa-4">
            <div class="text-truncate mr-20" v-if="item.hash" @click="getBscScanUrl(item.hash)">
              {{ item.hash }}
            </div>
            <div class="text-truncate mr-20" v-else>Sending to the address failed! You rejected in your wallet.</div>
            <div class="d-flex">
              <v-progress-circular
                indeterminate
                color="primary"
                v-if="item && item.loading"
                :size="20"
              ></v-progress-circular>
              <v-icon color="green" v-else-if="item.state">mdi-check-circle-outline</v-icon>
              <v-icon color="red" v-else>mdi-alert-circle-outline</v-icon>
              <v-tooltip bottom v-if="item.displayCopyIcon">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" class="ml-2" v-on="on" @click="copyHash(item)">mdi-content-copy</v-icon>
                </template>
                <span>Copy the addresses from this transaction</span>
              </v-tooltip>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" v-if="sendingCompleted">
        <div class="d-flex pa-3 error-footer" v-if="sendingError">
          <v-icon class="mr-4">mdi-alert-circle-outline</v-icon>
          <div class="text-body2">Sending is Error!</div>
        </div>
        <div class="d-flex pa-3 completed-footer" v-else>
          <v-icon class="mr-4">mdi-check-circle-outline</v-icon>
          <div class="text-body2">Sending is Completed!</div>
        </div>
      </v-col>
      <v-col cols="12" class="d-flex pb-0">
        <v-btn rounded class="primary" min-width="120px" @click="backToFirst()" :disabled="!sendingCompleted"
          >Back To First</v-btn
        >
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Stepper from '@/components/base/Stepper.vue'
import { every } from 'lodash'
export default {
  data() {
    return {
      isCopyIcon: false,
    }
  },
  components: {
    Stepper,
  },
  computed: {
    ...mapState('bulkSendPrepare', ['selectedToken', 'bulkSendInfo', 'isSendSameValue', 'editorData']),
    ...mapState('auth', ['multiSendHandler', 'provider']),
    ...mapState('bulkSendSending', ['data']),
    sendingCompleted: function () {
      return this.data.length === this.bulkSendInfo.transNumber && every(this.data, ['loading', false])
    },
    sendingError: function () {
      return this.sendingCompleted && every(this.data, ['state', false])
    },
  },
  methods: {
    ...mapActions('bulkSendSending', ['handleSending', 'onMouseEnter', 'onMouseLeave']),
    ...mapActions('bulkSendPrepare', ['resetData']),
    copyHash(item) {
      navigator.clipboard.writeText(item.transactionInfo)
    },
    backToFirst() {
      this.resetData()
      this.$router.go(-2)
    },
    getBscScanUrl(hashTrans) {
      if (this.provider.chainId === '0x38') window.open(`https://bscscan.com/tx/${hashTrans}`)
      else if (this.provider.chainId === '0x61') window.open(`https://testnet.bscscan.com/tx/${hashTrans}`)
    },
  },
  created() {
    this.handleSending({
      multiSendHandler: this.multiSendHandler,
      editorData: this.editorData,
      maxAccountPerTrans: this.bulkSendInfo.maxAccountPerTrans,
      selectedToken: this.selectedToken,
      isSendSameValue: this.isSendSameValue,
    })
  },
}
</script>
<style scoped>
td {
  padding-top: 18px;
  padding-left: 18px;
  padding-bottom: 18px;
}
table,
th,
td {
  border: 1px solid gray;
  border-collapse: collapse;
}
.error-footer {
  background: var(--v-error-lighten1) !important;
  border-radius: 4px;
}
.trans-info-scrollable {
  overflow-y: scroll;
  height: 300px;
}
.completed-footer {
  background: var(--v-success-lighten2) !important;
  border-radius: 4px;
}
</style>
